import React, { PureComponent } from "react";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { Row } from "react-bootstrap";
import { moneyFormat } from "storefront/features/shared/utils";
import withPusherSubscription from "storefront/features/shared/components/SubscriptionPusher";
import { withRouter } from "react-router-dom";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import Countdown from "storefront/features/shared/components/Countdown";
import { getCustomerId } from "storefront/features/shared/components/Header/selectors";
import { WinningStatus } from "./ActiveAuction.styled";
import { Link } from "react-router-dom";
import BidButton from "../../productGrid/components/BidButton";
import { isExpired } from "storefront/features/shared/utils";

class ActiveAuction extends PureComponent {
  state = {
    closed: isExpired(this.props.product.end_at),
  };

  onBidClose = () => {
    this.setState({ closed: true });
  };

  winningStatus() {
    const { product, customerId } = this.props;
    const winning = customerId === product.winning_customer_id;

    return (
      <WinningStatus color={winning === true ? "#32CD32" : "#FF5C5C"}>
        {winning === true ? "Winning" : "Losing"}
      </WinningStatus>
    );
  }

  setPathname(product) {
    return `${location.pathname}/quick-view/${product.handle}?auction_id=${product.id}`;
  }

  render() {
    const { product, customerId } = this.props;

    return (
      <div>
        <Row className="d-flex justify-content-between w-100">
          <div className="col-12 col-md-2 my-2">
            <Link to={this.setPathname(product)}>
              <img className="img-fluid" src={product.image_url} />
            </Link>
          </div>
          <span className="font-weight-bold text-center text-sm-left text-uppercase col-12 col-md-3 text-left d-flex flex-column justify-content-center my-2">
            {product.product_title}
          </span>
          <span className="col-12 col-md-1 d-flex flex-column justify-content-center my-2">
            {moneyFormat(product.current_bid, true)}
          </span>
          <div className="col-12 col-md-2 d-flex flex-column justify-content-center my-2">
            <Countdown endDate={product.end_at} onClose={this.onBidClose} />
          </div>
          <span className="col-12 col-md-2 d-flex flex-column justify-content-center my-2">
            {product.winning_customer}
            {this.winningStatus()}
          </span>
          <div className="col-12 col-md-2 d-flex flex-md-column justify-content-center my-2">
            <BidButton
              vendorPresent={false}
              closed={this.state.closed}
              product={product}
              onModalOpen={this.onModalOpen}
              showBidValue={true}
              width="155px"
            />
            {product.max_bid !== null &&
              product.max_bid > product.current_bid &&
              product.winning_customer_id === customerId && (
                <span>Max auto bid: {moneyFormat(product.max_bid, true)}</span>
              )}
          </div>
        </Row>
        <hr />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  customerId: getCustomerId,
});

export default compose(
  connect(mapStateToProps),
  withImmutablePropsToJS,
  withRouter,
  withPusherSubscription
)(ActiveAuction);
