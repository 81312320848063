import { call, put, takeLatest } from "redux-saga/effects";
import superagent from "superagent";

import * as actions from "storefront/features/shared/actions";

export function* fetchFilteredAuctionsRequest(action) {
  const { loader } = action.payload;
  const query = {
    page: action.payload.page,
    tags: action.payload.tags,
  };
  const request = () =>
    superagent.get(`/api/collections/all-auctions`).query(query);

  try {
    const response = yield call(request);
    yield put(actions.fetchAllAuctionProductsRequestResult(response.body));
    yield put(actions.removeLoader({ loader }));
  } catch (res) {
    console.log(`error - ${res}`);
  }
}

export default [
  takeLatest(
    actions.FETCH_FILTERED_AUCTIONS_REQUEST,
    fetchFilteredAuctionsRequest
  ),
];
