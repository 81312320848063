import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import { isEmpty } from "lodash";

import * as actions from "../../shared/actions";
import { updateQuickViewProduct } from "storefront/features/productGrid/actions";
import { updateSearchProduct } from "storefront/features/search/actions";
import { subscribePusher } from "../utils";
import { pusher } from "storefront/config/pusher";
import { updateUserAuctionData } from "storefront/features/shared/components/Header/actions";
import { getUserAuctionData } from "storefront/features/shared/components/Header/selectors";
import {
  getProduct,
  getQuickViewIsOpened,
} from "storefront/features/productGrid/selectors";
import { getProducts } from "storefront/features/shared/selectors";
import { getActiveAuctions } from "storefront/features/account/selectors";
import { updateCustomerActiveAuction } from "storefront/features/account/actions";
import { getCustomerId } from "storefront/features/shared/components/Header/selectors";

const withPusherSubscription = (WrappedComponent) => {
  class SubscriptionPusher extends PureComponent {
    componentDidMount() {
      const { productData } = this.props;
      if (!isEmpty(productData)) {
        this.pusherUnsubscribe();
      }
      this.pusherSubscribe();
    }

    componentWillUnmount() {
      const {
        updateQuickView,
        productData,
        bidAuctionData,
        activeAuctions,
        quickViewIsOpened,
      } = this.props;
      const isProductExist = isEmpty(bidAuctionData)
        ? false
        : !isEmpty(bidAuctionData.find(this.isProductExistInBidAuction));

      const activeAuctionExist = isEmpty(activeAuctions)
        ? false
        : !isEmpty(activeAuctions[productData.bidAuctionId]);

      this.pusherUnsubscribe();
      if (isProductExist || activeAuctionExist) {
        this.pusherSubscribe();
      }
      if (
        (!isEmpty(productData) && isProductExist) ||
        isEmpty(bidAuctionData)
      ) {
        !quickViewIsOpened && updateQuickView({});
      }
    }

    isProductExistInBidAuction = (bidAuctionProduct) => {
      const { productData } = this.props;
      return isEmpty(productData)
        ? {}
        : productData.bidAuctionId === bidAuctionProduct.bidAuctionId;
    };

    pusherSubscribe = () => {
      const {
        product,
        updateProduct,
        updateQuickView,
        updateAuctionData,
        userAuctionData,
        updateSearchProduct,
        activeAuctions,
        updateCustomerActiveAuction,
        customerId,
      } = this.props;
      try {
        subscribePusher({
          product,
          updateProduct,
          updateQuickView,
          updateAuctionData,
          userAuctionData,
          updateSearchProduct,
          activeAuctions,
          updateCustomerActiveAuction,
          customerId,
        });
      } catch (err) {
        console.log(err);
      }
    };

    pusherUnsubscribe = () => {
      const { product } = this.props;
      try {
        pusher.unsubscribe(`auction-${product.bidAuctionId}`);
      } catch (err) {
        console.log(err);
      }
    };

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return SubscriptionPusher;
};

const mapStateToProps = createStructuredSelector({
  activeAuctions: getActiveAuctions,
  userAuctionData: getUserAuctionData,
  productData: getProduct,
  bidAuctionData: getProducts,
  quickViewIsOpened: getQuickViewIsOpened,
  customerId: getCustomerId,
});

const mapDispatchToProps = (dispatch) => ({
  updateProduct: (product) => {
    dispatch(actions.updateProduct(product));
  },
  updateQuickView: (product) => {
    dispatch(updateQuickViewProduct(product));
  },
  updateAuctionData: (data) => {
    dispatch(updateUserAuctionData(data));
  },
  updateCustomerActiveAuction: (data) => {
    dispatch(updateCustomerActiveAuction(data));
  },
  updateSearchProduct: (product) => {
    dispatch(updateSearchProduct(product));
  },
});

export { withPusherSubscription };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withImmutablePropsToJS,
  withPusherSubscription
);
