import React, { PureComponent } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

import ProductImage from "./ProductImage";
import {
  getSearchParams,
  getImageData,
} from "storefront/features/shared/utils";
import Auction from "./Auction";
import withPusherSubscription from "storefront/features/shared/components/SubscriptionPusher";
import ProductRetailPrice from "./ProductRetailPrice";
import { StyledImageAndTitle } from "./Product.style";
import SoldByLink from "./SoldByLink";

class Product extends PureComponent {
  setPathname = (location, handle, bidAuctionId) => {
    const pageNumber = getSearchParams(location.search, "page");
    const searchTitle = getSearchParams(location.search, "title");
    const buyingFormat = getSearchParams(location.search, "buying_format");
    const homeLocation =
      pageNumber > 1
        ? `${location.pathname}quick-view/${handle}?page=${pageNumber}&auction_id=${bidAuctionId}`
        : `${location.pathname}quick-view/${handle}?auction_id=${bidAuctionId}`;
    const pageLocation =
      pageNumber > 1
        ? `${location.pathname}/quick-view/${handle}?page=${pageNumber}&auction_id=${bidAuctionId}`
        : `${location.pathname}/quick-view/${handle}?auction_id=${bidAuctionId}`;
    const searchLocation =
      searchTitle && buyingFormat
        ? `${pageLocation}&title=${searchTitle}&buying_format=${buyingFormat}`
        : pageLocation;
    return location.pathname === "/" ? homeLocation : searchLocation;
  };

  render() {
    const {
      product,
      product: {
        handle,
        title,
        variants,
        bidAuctionId,
        images,
        vendor_name,
        vendor_slug,
      },
    } = this.props;

    if (variants === undefined) {
      return <></>;
    }

    const { shopify_id, price, compare_at_price } = variants[0];
    const FIRST_IMAGE_INDEX = 0;
    return (
      <Card className="text-center h-100">
        <Card.Body className="p-2">
          <StyledImageAndTitle>
            <Link
              to={(location) =>
                this.setPathname(location, handle, bidAuctionId)
              }
            >
              <ProductImage
                {...getImageData(images, FIRST_IMAGE_INDEX)}
                className="gallery-image"
              />
              <p className="product-title">{title}</p>
            </Link>
          </StyledImageAndTitle>
          <Auction
            product={product}
            variantId={shopify_id}
            productPrice={price}
            showBidValue={true}
          />
          <ProductRetailPrice retailPrice={compare_at_price} />
          <p>
            <small>
              Sold by
              <SoldByLink vendorSlug={vendor_slug} vendorName={vendor_name} />
            </small>
          </p>
        </Card.Body>
      </Card>
    );
  }
}

export default withPusherSubscription(Product);
