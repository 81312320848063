import React, { PureComponent } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { isEmpty } from "lodash";
import withImmutablePropsToJS from "with-immutable-props-to-js";

import Icon from "storefront/features/shared/icons";
import Logo from "storefront/img/bids.com.svg";
import NavBar from "./NavBar";
import MobileNavBar from "./MobileNavBar";
import * as actions from "../actions";
import {
  getAuctionData,
  getMobileMenuState,
  getCustomer,
  getCheckoutLineItems,
  getUserAuctionData,
} from "../selectors";
import {
  HeaderLogo,
  MenuDrawer,
  MobileMenuOverlay,
  BadgeStyled,
  HeaderMenuStyledLink,
  HeaderMenuStyledTagA,
  HeaderIconStyledLink,
  VolumeIcon,
  StyleIcons,
  HeaderMenu,
} from "./Header.styled";
import {
  isLogin,
  subscribeWonAuctionsPusher,
  getCheckoutLineItemsCount,
  getCountActiveAuctions,
  ACCOUNT_PAGE_LOADER,
} from "storefront/features/shared/utils";
import {
  menuSelector,
  getVendorInfo,
} from "storefront/features/shared/selectors";
import { Badge } from "storefront/features/shared/components/Badge";
import {
  fetchWonAuctionsCountRequest,
  fetchWonAuctionsCountRequestResult,
} from "storefront/features/cart/actions";
import { getWonAuctionsCount } from "storefront/features/cart/selectors";
import { pusher } from "storefront/config/pusher";
import { Fireworks } from "storefront/features/fireworks";
import { setSoundState } from "../utils";
import { Hymn } from "storefront/features/hymns";
import SearchInput from "storefront/features/search/components/SearchInput";

class Header extends PureComponent {
  state = {
    checkoutItemsCount: 0,
    activeItemsCount: 0,
    showFireworks: false,
    muted: false,
    countryCode: "",
  };

  componentDidMount() {
    const {
      fetchCustomer,
      fetchUserAuctionData,
      fetchWonAuctionsCountRequest,
    } = this.props;
    const soundState = localStorage.getItem("Sound-State");
    if (isLogin()) {
      fetchCustomer(ACCOUNT_PAGE_LOADER);
      fetchUserAuctionData();
      fetchWonAuctionsCountRequest();
      isEmpty(soundState)
        ? localStorage.setItem("Sound-State", "soundOn")
        : localStorage.setItem("Sound-State", soundState);
      this.setState({ muted: soundState === "soundOff" });
    } else {
      localStorage.removeItem("Customer-Access-Token");
      localStorage.removeItem("Customer-Access-Token-ExpiresAt");
      localStorage.removeItem("Multipass-Token");
      localStorage.removeItem("Checkout-Id");
    }
  }

  componentDidUpdate(prevProps) {
    const {
      customer: { id },
      fetchWonAuctionsCountRequestResult,
      wonAuctionsCount,
      checkoutLineItems,
      userAuctionData,
      mobileMenuState,
    } = this.props;
    const checkoutLineItemsCount = isEmpty(checkoutLineItems)
      ? 0
      : getCheckoutLineItemsCount(checkoutLineItems);
    const checkoutItemsCount = checkoutLineItemsCount + wonAuctionsCount;
    const activeItemsCount = getCountActiveAuctions(userAuctionData);
    if (id && !prevProps.customer.id) {
      try {
        subscribeWonAuctionsPusher(
          id,
          fetchWonAuctionsCountRequestResult,
          wonAuctionsCount,
          this.showFireworks,
          this.playHymn
        );
      } catch (err) {
        console.log(err);
      }
    }
    this.setState({ checkoutItemsCount, activeItemsCount });
    this.preventScrolling(mobileMenuState);
  }

  componentWillUnmount() {
    const {
      customer: { id },
    } = this.props;
    try {
      pusher.unsubscribe(`customer-${id}`);
    } catch (err) {
      console.log(err);
    }
  }

  showFireworks = () => {
    this.setState({ showFireworks: true });
    setTimeout(() => this.setState({ showFireworks: false }), 7000);
  };

  playHymn = (countryCode) => {
    this.setState({ countryCode });
  };

  handleSoundIconClick = () => {
    const { muted } = this.state;
    setSoundState();
    this.setState({ muted: !muted });
  };

  handleMenuButtonClick = () => {
    const { mobileMenuToggle } = this.props;
    mobileMenuToggle(true);
  };

  handleCloseMenuButton = () => {
    const { mobileMenuToggle } = this.props;
    mobileMenuToggle(false);
  };

  preventScrolling = (value) => {
    if (value) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  };

  navMenuPrefix = () => {
    const {
      location: { pathname = "" },
    } = this.props;
    const menuItemPrefix = pathname.toLowerCase();
    if (menuItemPrefix.includes("/collections/buy-it-now")) {
      return "/collections/buy-it-now";
    }
    if (menuItemPrefix.includes("/collections/make-an-offer")) {
      return "/collections/make-an-offer";
    }
    return "/collections/all-auctions";
  };

  isVendorPage = () => {
    const {
      location: { pathname },
    } = this.props;
    return pathname.includes("/vendors/");
  };

  render() {
    const {
      mobileMenuState,
      customer,
      topMenu,
      vendorInfo,
      customerData: { vendor },
    } = this.props;
    const {
      checkoutItemsCount,
      activeItemsCount,
      showFireworks,
      muted,
      countryCode,
    } = this.state;
    const isUserLogged = isEmpty(customer);
    return (
      <>
        {showFireworks && <Fireworks />}
        <Hymn
          muted={muted}
          countryCode={countryCode}
          playHymn={this.playHymn}
        />

        <Container fluid className="my-2 my-xl-0 px-3">
          <Row className="align-items-center justify-content-between border-bottom border-lg-bottom-0 justify-content-xl-center">
            <HeaderLogo
              className="pr-0 pr-sm-5 pr-md-0 order-xl-1"
              lg={1}
              md={2}
              xs={3}
            >
              <Link to="/">
                <img
                  id="bids-logo"
                  className="img-fluid"
                  src={Logo}
                  alt="Bids.com"
                />
              </Link>
            </HeaderLogo>
            <Col
              className="header-menu pl-lg-5 d-none d-xl-block order-xl-2"
              xl={8}
            >
              <Row className="flex-column">
                <HeaderMenu className="mb-2 text-center">
                  <HeaderMenuStyledLink
                    id="header-desktop-auction-link"
                    bottombordercolor={`#ff2800`}
                    className="px-2 text-uppercase"
                    to="/collections/all-auctions"
                  >
                    Auction
                  </HeaderMenuStyledLink>
                  <HeaderMenuStyledLink
                    id="header-desktop-buy-now-link"
                    bottombordercolor={`#4f703c`}
                    className="px-2 text-uppercase"
                    to="/collections/buy-it-now"
                  >
                    Buy now
                  </HeaderMenuStyledLink>
                  {/* <HeaderMenuStyledLink
                    id="header-desktop-make-an-offer-link"
                    bottombordercolor={`#00509d`}
                    className="px-2 text-uppercase"
                    to="/collections/make-an-offer"
                  >
                    Make an offer
                  </HeaderMenuStyledLink> */}
                  {!isUserLogged ? (
                    <HeaderMenuStyledTagA
                      id="header-desktop-partner-dashboard-link"
                      bottombordercolor={`#ff1d8e`}
                      className="px-2 text-uppercase"
                      href="https://bids.tapfiliate.com/login"
                    >
                      Affiliate Program
                    </HeaderMenuStyledTagA>
                  ) : (
                    <HeaderMenuStyledLink
                      id="header-desktop-make-money-link"
                      bottombordercolor={`#ff1d8e`}
                      className="px-2 text-uppercase"
                      to="/pages/ez-make-money-with-bids"
                    >
                      Affiliate
                    </HeaderMenuStyledLink>
                  )}
                  <HeaderMenuStyledTagA
                    id="header-desktop-investors-link"
                    bottombordercolor={`#0fc382`}
                    className="px-2 text-uppercase"
                    href="https://bidscom.mailchimpsites.com/"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <Badge color={"#0fc382"} title={"New"}>
                      Investors
                    </Badge>
                  </HeaderMenuStyledTagA>
                </HeaderMenu>
                <HeaderMenu className="order-xl-3 d-none d-xl-flex justify-content-center">
                  <SearchInput />
                </HeaderMenu>
              </Row>
            </Col>
            <StyleIcons
              className="header-links d-flex justify-content-end ml-auto order-xl-4 pl-0"
              xl={3}
              xs={6}
            >
              <VolumeIcon
                className="px-1 d-flex flex-column position-relative align-items-center"
                onClick={this.handleSoundIconClick}
                data-cy="header-sound-switch-btn"
              >
                {muted ? (
                  <>
                    <Icon type="volumeMute" />
                    <span
                      className="d-none d-xl-inline small text-center"
                      id="header-sound-off-btn"
                    >
                      Sound off
                    </span>
                  </>
                ) : (
                  <>
                    <Icon type="volumeUp" />
                    <span
                      className="d-none d-xl-inline small text-center"
                      id="header-sound-on-btn"
                    >
                      Sound on
                    </span>
                  </>
                )}
              </VolumeIcon>
              {!vendor && (
                <>
                  <HeaderIconStyledLink
                    to="/account/register_vendor"
                    className="d-flex flex-column align-items-center"
                  >
                    <Icon type="vendorRegisterIcon" />
                    <span className="d-none d-xl-inline small text-center">
                      Vendor register
                    </span>
                  </HeaderIconStyledLink>
                </>
              )}
              {isUserLogged ? (
                <>
                  <HeaderIconStyledLink
                    id="header-desktop-register-link"
                    to="/account/register"
                    className="d-flex flex-column align-items-center"
                  >
                    <Icon type="register" />
                    <span className="d-none d-xl-inline small">Register</span>
                  </HeaderIconStyledLink>
                  <HeaderIconStyledLink
                    id="header-desktop-login-link"
                    to="/account/login"
                    className="d-flex flex-column align-items-center"
                  >
                    <Icon type="login" />
                    <span className="d-none d-xl-inline small">Login</span>
                  </HeaderIconStyledLink>
                </>
              ) : (
                <>
                  <HeaderIconStyledLink
                    id="header-desktop-active-auctions-link"
                    to="/account/active-auctions"
                    className="d-flex flex-column position-relative align-items-center"
                  >
                    <Icon type="auction" />
                    {activeItemsCount > 0 && (
                      <BadgeStyled id="header-active-auctions-badge" pill>
                        {activeItemsCount}
                      </BadgeStyled>
                    )}
                    <span className="d-none d-xl-inline small">
                      Active Auctions
                    </span>
                  </HeaderIconStyledLink>
                  <HeaderIconStyledLink
                    id="header-desktop-account-link"
                    to="/account/my-account"
                    className="d-flex flex-column align-items-center"
                    data-cy="my-account-link"
                  >
                    <Icon type="login" />
                    <span className="d-none d-xl-inline small">My account</span>
                  </HeaderIconStyledLink>
                </>
              )}
              <HeaderIconStyledLink
                id="header-cart-link"
                to="/cart"
                className="d-flex flex-column position-relative align-items-center"
              >
                <Icon type="cart" />
                {checkoutItemsCount > 0 && (
                  <BadgeStyled id="header-cart-badge" pill>
                    {checkoutItemsCount}
                  </BadgeStyled>
                )}
                <span className="d-none d-xl-inline small">Cart</span>
              </HeaderIconStyledLink>
              <a
                id="header-menu-hamburger"
                className="px-2 d-xl-none"
                onClick={this.handleMenuButtonClick}
              >
                <Icon type="menu" />
              </a>
            </StyleIcons>
            <Col
              xl={3}
              className="pt-lg-3 order-xl-3 d-xl-none mobile-search-bar"
            >
              <SearchInput />
            </Col>
          </Row>
        </Container>
        <div className="d-none d-xl-block">
          <NavBar
            menu={topMenu}
            prefix={this.navMenuPrefix()}
            isVendorPage={this.isVendorPage()}
            vendorInfo={vendorInfo}
          />
        </div>
        <CSSTransition
          in={mobileMenuState}
          classNames="mobile-menu"
          unmountOnExit
          timeout={300}
        >
          <MenuDrawer>
            <MobileNavBar
              menu={topMenu}
              prefix={this.navMenuPrefix()}
              isUserLogged={isUserLogged}
              closeFunction={this.handleCloseMenuButton}
              isVendorPage={this.isVendorPage()}
              vendorInfo={vendorInfo}
            />
            <MobileMenuOverlay
              className="w-100 h-100"
              onClick={this.handleCloseMenuButton}
            />
          </MenuDrawer>
        </CSSTransition>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  mobileMenuState: getMobileMenuState,
  customer: getCustomer,
  customerData: getAuctionData,
  wonAuctionsCount: getWonAuctionsCount,
  checkoutLineItems: getCheckoutLineItems,
  topMenu: menuSelector,
  vendorInfo: getVendorInfo,
  userAuctionData: getUserAuctionData,
});

const mapDispatchToProps = (dispatch) => ({
  mobileMenuToggle: (value) => {
    dispatch(actions.mobileMenuToggle(value));
  },
  fetchCustomer: (loader) => {
    dispatch(actions.fetchCustomerRequest({ loader }));
  },
  fetchUserAuctionData: () => {
    dispatch(actions.fetchUserAuctionDataRequest());
  },
  fetchWonAuctionsCountRequest: () => {
    dispatch(fetchWonAuctionsCountRequest());
  },
  fetchWonAuctionsCountRequestResult: (value) => {
    dispatch(fetchWonAuctionsCountRequestResult(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withImmutablePropsToJS(Header)));
